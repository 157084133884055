<template>
<div>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- Cabecera -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Boton Agregar nueva Factura de Compra -->
          <v-btn
            v-if="panel==0"
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="orange"
            small
            fab dark
            @click="sincronizarInfoRently()"
            title="Sincronizar info RENTLY"
          >
            <v-icon small>fas fa-sync</v-icon>
          </v-btn>
          <!-- Titulo Filtros -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtros
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Filtros -->
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <!-- Empresa, Proveedor, Fecha Desde y Fecha Hasta -->
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="3" md="3" class="py-1">
                  ID Reserva
                  <v-text-field
                    v-model="filtroReservas.reserva_id"
                    clearable
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtroReservas.estado_id"
                    item-text="estado_nombre"
                    item-value="estado_id"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Cliente
                  <v-text-field
                    v-model="filtroReservas.cliente_nombre"
                    clearable
                    outlined
                    type="text"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Lugar retiro
                  <v-autocomplete
                    v-model="filtroReservas.retiro_id"
                    item-text="lugar_nombre"
                    item-value="lugar_id"
                    :items="lugares"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!-- Id Factura Compra, Estado, Tipo Factura, Emision y Numero -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
                <v-col cols="12" sm="2" md="2" class="py-0">
                  Entrega desde
                  <FechaPickerVue
                    clearable
                    v-model="filtroReservas.retiro_desde"
                    hide-details
                    ref="fechaPagoEstimada"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  Entrega Hasta
                  <FechaPickerVue
                    clearable
                    v-model="filtroReservas.retiro_hasta"
                    hide-details
                    ref="fechaPagoEstimada"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  Devolución Desde
                  <FechaPickerVue
                    clearable
                    v-model="filtroReservas.entrega_desde"
                    hide-details
                    ref="fechaPagoEstimada"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-0">
                  Devolución Hasta
                  <FechaPickerVue
                    clearable
                    v-model="filtroReservas.entrega_hasta"
                    hide-details
                    ref="fechaPagoEstimada"
                  />
                </v-col>
                <v-col cols="12" sm="3" md="4" class="py-0">
                  Modelo
                  <v-autocomplete
                    v-model="filtroReservas.modelo_id"
                    item-text="modelo_nombre"
                    item-value="modelo_id"
                    :items="modelos"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!-- Botones -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-0">
                <v-col cols="12" class="pt-4">
                  <BtnFiltroVue
                    :loading="load"
                    @clear="limpiar()"
                    class="pt-0"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Listado -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="header"
        :items="reservas"
        :loading="load"
        dense
        :search="search"
        @contextmenu:row="clickDerecho"
        :single-select="false"
        item-key="reserva_id"
        :item-class="miBand ? ponerSinBlod : ponerBold"
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in header.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- Cliente = cliente_apellido + cliente_nombre -->
        <template  v-slot:[`item.cliente`]="{ item }">
          {{ item.cliente_apellido + ' ' + item.cliente_nombre  }}
        </template>
        <!-- retiro_dia -->
        <template  v-slot:[`item.retiro_dia`]="{ item }">
          {{ moment(item.retiro_dia).format('DD/MM/YY') }}
        </template>
        <!-- entrega_dia -->
        <template  v-slot:[`item.entrega_dia`]="{ item }">
          {{ moment(item.entrega_dia).format('DD/MM/YY') }}
        </template>
        <!-- Acciones -->
        <template v-slot:[`item.accion`]="{ item }">
          <div class="d-flex">
            <!-- <v-tooltip bottom>
              <template  v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  !-- open-on-hover --
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        @click="controlarActual(item)"
                      >
                        <v-icon small color="info">fas fa-bars</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="verEditarPrefactura(item,1)">
                        <v-list-item-title>
                          <v-row>
                            <v-col cols="2">
                              <v-icon small color="success" title="Ver reserva" class="pe-2">fas fa-eye</v-icon>
                            </v-col>
                            <v-col cols="10">
                              Ver Reserva
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="verEditarPrefactura(item,2)">
                        <v-list-item-title>
                          <v-row>
                            <v-col cols="2">
                              <v-icon small color="accent" title="Prefactura">fas fa-dollar-sign</v-icon>
                            </v-col>
                            <v-col cols="10">
                              Prefactura
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="sincronizarUnaReserva(item.reserva_id)"
                        v-if="item.reserva_estado_id == 1"
                      >
                        <v-list-item-title>
                          <v-row>
                            <v-col cols="2" class="text-center">
                              <v-icon color="orange" small left>fas fa-sync</v-icon>
                            </v-col>
                            <v-col cols="10">
                              Sincronizar Reserva
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      !-- <v-list-item
                        @click="cancelarReserva(actualReserva.reserva_id)"
                        v-if="actualReserva.reserva_estado_id == 1 || actualReserva.reserva_estado_id == 2"
                      >
                        <v-list-item-title>
                          <v-row>
                            <v-col cols="2" class="text-center">
                              <v-icon color="error" small left>fas fa-trash</v-icon>
                            </v-col>
                            <v-col cols="10">
                              Cancelar Reserva
                            </v-col>
                          </v-row>                           
                        </v-list-item-title>
                      </v-list-item> --
                    </v-list>
                  </v-menu>
                </v-btn>
              </template>
              <span>
                Acciones
              </span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template  v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="verEditarPrefactura(item,1)">
                  <v-icon small color="success">fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span> Ver reserva </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template  v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="verEditarPrefactura(item,2)"><v-icon small color="accent">fas fa-dollar-sign</v-icon></v-btn>
              </template>
              <span> Prefactura </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template  v-slot:activator="{ on }">
                <v-btn v-on="on" icon small v-if="item.reserva_estado_id == 1" @click="sincronizarUnaReserva(item.reserva_id)"><v-icon color="orange" small left>fas fa-sync</v-icon></v-btn>
              </template>
              <span> Sincronizar Reserva </span>
            </v-tooltip>
          </div>
        </template>
        <!-- Msj que se mostrara si no existen resultados -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- Menu del Click Derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="verEditarPrefactura(actualReserva,1)">
          <v-list-item-title>
            <v-row>
              <v-col cols="2">
                <v-icon small color="success" title="Ver reserva" class="pe-2">fas fa-eye</v-icon>
              </v-col>
              <v-col cols="10">
                Ver Reserva
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="verEditarPrefactura(actualReserva,2)">
          <v-list-item-title>
            <v-row>
              <v-col cols="2">
                <v-icon small color="accent" title="Prefactura">fas fa-dollar-sign</v-icon>
              </v-col>
              <v-col cols="10">
                Prefactura
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="sincronizarUnaReserva(actualReserva.reserva_id)"
          v-if="actualReserva.reserva_estado_id == 1"
        >
          <v-list-item-title>
            <v-icon color="orange" small left>fas fa-sync</v-icon>
            Sincronizar Reserva
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="reabrirReserva(actualReserva.reserva_id)"
          v-if="actualReserva.reserva_estado_id == 5 && permisoReabrir == 1"
        >
          <v-list-item-title>
            <v-icon color="info" small left>fas fa-undo</v-icon>
            Reabrir Reserva
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-row>
</div>
</template>

<script>
import moment from 'moment'
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import FechaPickerVue from '../../components/util/FechaPicker.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import { format_money, objetoNoVacio } from '../../util/utils'

export default {
  name: 'IndexReservas',
  data(){
    return{
      moment: moment,
      panel: 0,
      load: false,
      search: '',
      filtroReservas: {
        reserva_id: null,
        estado_id: null,
        cliente_nombre: null,
        retiro_id: null,
        retiro_desde: null,//fechaEntregaDesde
        retiro_hasta: null,//fechaEntregaHasta
        entrega_desde: null, //fechaDevDesde
        entrega_hasta: null,//fechaDevHasta
        modelo_id: null,
      },
      filtroReservasCopia: {},
      estados: [],
      lugares: [],
      modelos:[],
      reservas:[],
      header: [
        { text: 'ID', value: 'reserva_id'},
        { text: 'Estado', value: 'reserva_estado'},
        { text: 'Cliente', value: 'cliente'},
        { text: 'Modelo', value: 'vehiculo_modelo', width: '20%'},
        { text: 'Lugar Entrega', value: 'lugar_retiro'},
        { text: 'Entrega', value: 'retiro_dia'},
        { text: 'Devolución', value: 'entrega_dia'},
        { text: 'Importe', value: 'importe_total', align: 'end', formatter:format_money},
        { text: 'Pagado', value: 'importe_pagado', align: 'end', formatter:format_money },
        { text: 'Vehículo', value: 'vehiculo'},
        //{ text: 'Origen', value: 'origen'},
        { text: 'Acciones', value: 'accion', align:'center'},
      ],
      miBand: false,
      actualReserva: {},
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      puedeReabrir: 0,
      permisoReabrir: 0,
      puedeReabrirComprobantes: [],
    }
  },
  created(){
    let filtro = localStorage.getItem('filtroIndexReservas')
    if(filtro){
      this.filtroReservas = JSON.parse(filtro);
    }
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('reservas/iniciarFormulario',{ reserva_id: 0, funcion_codigo: parseInt(161) })
      this.$store.state.loading = false;
      if(res.resultado == 1){
        this.estados        = res.estados;
        this.lugares        = res.lugares;
        this.modelos        = res.modelos;
        this.permisoReabrir = res.permiso;
      }else{
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error',
        })
        return
      }
    },
    async sincronizarInfoRently(){
      this.$swal.fire({
        icon: 'warning',
        title: `Está seguro de sincronizar la reserva ${id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
          if(res.isConfirmed){
            this.$store.state.loading = true;
            const res = await this.$store.dispatch('reservas/sincronizarTodas')
            this.$store.state.loading = false;
            if(res.resultado == 0){
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error',
              })
              return
            }
            this.$store.dispatch('show_snackbar', {
              text: "Sincronización exitosa.",
              color: 'success',
            })
          }
        })
    },
    validarCampos(){
      let error = {};
      if(!this.filtroReservas.reserva_id && !this.filtroReservas.estado_id
      && !this.filtroReservas.cliente_nombre && !this.filtroReservas.retiro_id
      && !this.filtroReservas.retiro_desde && !this.filtroReservas.retiro_hasta
      && !this.filtroReservas.entrega_desde && !this.filtroReservas.entrega_hasta
      && !this.filtroReservas.modelo_id){
        error = {
          text: 'Se requiere ingresar al menos un filtro.',
          color: 'warning',
        }
      }
      return error
    },
    async buscar(){
      let error = this.validarCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }

      localStorage.setItem('filtroIndexReservas', JSON.stringify(this.filtroReservas))
      this.reservas = [];
      this.load = true;
      this.miBand = true;
      this.actualReserva = {};
      this.search = '';

      this.$store.state.loading = true;
      const res = await this.$store.dispatch('reservas/buscarReservas',this.filtroReservas)
      this.$store.state.loading = false;
      this.load = false;
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error',
        })
      }
      this.reservas = res.reservas;
      this.miBand = false;
    },
    limpiar(){
      localStorage.removeItem('filtroIndexReservas')
      this.reservas = [];
      this.search = '';
      this.miBand = true;
      this.filtroReservas = {
        reserva_id: null,
        estado_id: null,
        cliente_nombre: null,
        retiro_id: null,
        retiro_desde: null,//fechaEntregaDesde
        retiro_hasta: null,//fechaEntregaHasta
        entrega_desde: null, //fechaDevDesde
        entrega_hasta: null,//fechaDevHasta
        modelo_id: null,
      };
    },
    verEditarPrefactura(item, opc){
      let path = `/reserva/${item.reserva_id}`
      if(opc == 2){
        path = `/reserva/prefactura/${item.reserva_id}` 
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    async cancelarReserva(id){
      this.$swal.fire({
        icon: 'warning',
        title: `Está por cancelar la reserva ${id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true;
          const res = await this.$store.dispatch('reservas/cancelarReserva', { reserva_id: id })
          this.$store.state.loading = false;
          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error',
            })
          }
          this.$store.dispatch('show_snackbar', {
            text: "Reserva cancelada de forma exitosa.",
            color: 'success',
          })
        }
      })  
    },
    async sincronizarUnaReserva(id){
      this.$swal.fire({
        icon: 'warning',
        title: `Está seguro de sincronizar la reserva ${id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true;
          const res = await this.$store.dispatch('reservas/sincronizarUnaReserva', { reserva_id: id })
          this.$store.state.loading = false;
          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error',
            })
          }
          this.$store.dispatch('show_snackbar', {
            text: "Sincronización exitosa.",
            color: 'success',
          })
          this.buscar();
        }
      })  
      
    },
    controlarActual(item){
      this.actualReserva = item;
    },
    clickDerecho(event, item){
      event.preventDefault()
      this.actualReserva = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        this.actualReserva = item.item
      })
    },
    ponerBold(item){
      return item.reserva_id === this.actualReserva.reserva_id ? ['font-weight-bold'] : '';
    },
    ponerSinBlod(){
      return '';
    },
    desplegarMenu(item){
      this.actualReserva = {}
      this.menu.activo = false
      this.menu.x = 0
      this.menu.y = 0
      //this.$nextTick(() => {
        this.menu.activo = true
        this.actualReserva = item
      //})
    },
    //hace la accion de reabrir la reserva, cambia los estados
    async reabrirReserva(id){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('reservas/comprobarReapertura', { reserva_id: id })
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      
      this.puedeReabrir               = res.puede_reabrir;
      this.puedeReabrirComprobantes   = res.tipos_comprobantes;

      if(this.puedeReabrir == 1){ //cumple las condiciones de que NO tiene check out en TELECOM.COMPROBANTE_CAJA
        let modal = await this.$swal.fire({
          icon: 'warning',
          title: 'Confirmar acción',
          text: `¿Está seguro de reabrir la reserva: ${id}?`,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (!modal.isConfirmed) return
        
        let tiene107 = this.puedeReabrirComprobantes.filter(e => e.tipo_comp_id == 107);
        let estadoNuevo = 2;

        let modal1
        let dialog_reabrir = true;
        if(tiene107.length == 0){
          //abrir modal para elegir que estado: abierto o en renta
          
          modal1 = await this.$swal.fire({
            icon: 'question',
            title: 'Seleccione un estado',
            text: `Debe seccionar el nuevo estado en el cual quedará la reserva ${id} reabierta:`,
            confirmButtonText: 'Abierto',
            denyButtonText: 'En renta',
            denyButtonColor: '#2196F3', //#4EBCEF
            showDenyButton: true,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
          });
          
          if(modal1.hasOwnProperty('value')){
            if(modal1.isConfirmed){
              estadoNuevo = 1;
            }
          } else dialog_reabrir = false;       
        }
        if(dialog_reabrir){
          this.$store.state.loading = true
          const res = await this.$store.dispatch('reservas/cambiarEstadoReservaReabrir', { 
            reserva_id: id,
            estado_cambiar: estadoNuevo
          });
          this.$store.state.loading = false

          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error',})
          }

          this.filtroReservas = {
            reserva_id: id,
            estado_id: null,
            cliente_nombre: null,
            retiro_id: null,
            retiro_desde: null,//fechaEntregaDesde
            retiro_hasta: null,//fechaEntregaHasta
            entrega_desde: null, //fechaDevDesde
            entrega_hasta: null,//fechaDevHasta
            modelo_id: null,
          }
          this.buscar();

          this.$swal.fire({
            icon: 'success',
            title: `Reapertura exitosa` ,
            text: `La reserva ${id} ha sido reabierta con éxito.`,
            timer: 4000
          });
        }

      }else{
        return this.$store.dispatch('show_snackbar', {text: 'No es posible Reabrir esta reserva porque ya tiene un Check Out asociado.', color: 'info', });
      }
    }
  },
  computed:{

  },
  components:{
    FechaPickerVue, BtnFiltroVue, SearchDataTableVue
  },
  watch:{

  }
}
</script>

<style>

</style>